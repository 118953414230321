import "./../components/forms/get_a_quote/get-a-quote.scss"

import React, { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"
// import { CustomMarkdown } from "../plugins/sanitizehtml"
import Loading from "../components/Loading"

import { useForm } from "react-hook-form"

import arrowGrey from "../images/get-a-quote/dropdown-arrow-disable.webp"
import arrowDown from "../images/get-a-quote/dropdown-arrow-down.webp"
import arrowUp from "../images/get-a-quote/dropdown-arrow-up.webp"
import axios from "../plugins/axios"
import {
  birthdayMasking,
  getCountryList,
  getPhoneCodes,
  isObjectEmpty,
  validateBirthday,
} from "../plugins/common"
import Button from "../components/Button"
import { createPipedriveEntry } from "../DAO/eqtDAO"
import { pipedriveValue } from "../enumerator/pipedriveValue"
import Dash from "../components/Dash"
import Confirmation from "../components/forms/get_a_quote/confirmation"
import Slogan from "../components/forms/get_a_quote/slogan"

const ContactFormPage = ({ pageContext, location }) => {
  const { data, labels, form, general } = pageContext
  const { aleaToken } = general
  const {
    headerTitle,
    sectionTitle,
    sectionSubtitle,
    formTitle,
    formCompletion,
    meta,
    localizations,
    locale,
  } = data
  //   console.log(data)
  //   console.log(labels)
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  const countryList = getCountryList()
  const phoneCodeList = getPhoneCodes()
  const [validBirthday, setValidBirthday] = useState(true)
  const [phoneValid, setPhoneValid] = useState(true)
  const [openResidence, setOpenResidence] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [progressTitle, setProgressTitle] = useState(sectionTitle)
  const [progressSubtitle, setProgressSubtitle] = useState(sectionSubtitle)

  //   const stepTitle = [formTitle, "Thank you"]

  const circle = "../images/process/circle.webp"
  const onSubmit = data => {
    // console.log("sumit data")
    // console.log(data)

    const birthArr = data.birthday.split("/")
    if (
      !hasEnteredBirthday() ||
      !parseInt(data.birthday.replaceAll("/", "")) ||
      !validateBirthday(
        parseInt(birthArr[0]),
        parseInt(birthArr[1]),
        parseInt(birthArr[2])
      )
    ) {
      setValidBirthday(false)
      return
    } else setValidBirthday(true)

    //validate phone
    //   if (!isPhoneValid(data.countryCode, data.phoneNumber)) {
    //     setPhoneValid(false)
    //     return
    //   } else setPhoneValid(true)

    addDataToPipeDrive(data)
  }

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, touchedFields },
  } = useForm({
    // reValidateMode: onSubmit,
  })

  const watchFirstName = watch("firstname", "")
  const watchLastName = watch("lastname", "")
  const watchPhone = watch("phoneNumber", "")
  const watchEmail = watch("email", "")
  const watchBirthday = watch("birthday", "")
  const watchResidence = watch("residence", "")
  const addDataToPipeDrive = async data => {
    setLoading(true)
    const pipedriveLanguage = [
      {
        name: "en",
        id: 160,
      },
      {
        name: "fr",
        id: 161,
      },
      {
        name: "zh-Hant",
        id: 162,
      },
    ]
    const pipedriveEntry = {
      firstname: data.firstname,
      lastname: data.lastname,
      yyyy: data.birthday.split("/")[2],
      mm: data.birthday.split("/")[1],
      dd: data.birthday.split("/")[0],
      countryCode: data.countryCode,
      phone: data.phoneNumber,
      email: data.email,
      residence: data.residence,
      isOrg: 71,
      benefitIds: [],
      note: "",
      nbPeopleInsure: 1,
      insuranceClass: 25,
      preferedLanguage:
        pipedriveLanguage.find(pLangue => pLangue.name == lang)?.id ?? 160,
      source: [pipedriveValue.GAQ_LIFE],
      type: "get-a-quote-life",
    }

    try {
      // console.log("pipedriveEntry: ", pipedriveEntry)
      const pipedriveRes = await createPipedriveEntry(
        aleaToken,
        pipedriveEntry
      ).catch(err => {
        throw err
      })
      // //log
      const log = {
        title: data.firstname + " " + data.lastname,
        lang: lang,
        raw: pipedriveEntry,
      }
      await axios
        .strapi(aleaToken)
        .post("/get-a-quote-submissions", log)
        .catch(err => {
          throw err
        })

      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: {
            hitType: "event",
            eventCategory: "form submission",
            eventAction: "lead",
          },
        })
      }

      updateStep(2)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const hasEnteredBirthday = () => watchBirthday.length

  const hasSelectedResidence = () => watchResidence && watchResidence !== ""

  const updateStep = val => {
    setStep(val)
    setProgressTitle(formCompletion.title)
    // setProgressSubtitle(progressSubtitleList[val])
    // quoteRef.current.scrollIntoView()
  }
  return (
    <Layout
      props={pageContext.general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
      isB2B={data.isB2B}
    >
      <div className="getQuote">
        <Slogan title={headerTitle} />
        <div className="card container ming soft-shadows soft-corners ">
          <div className="progressTitle">
            <div className="row ">
              <div className="col">
                <h2>{progressTitle}</h2>
                {step === 1 && <div>{progressSubtitle}</div>}
              </div>
              {/* <div className="col black-text">
                <div>{subtitle}</div>
              </div> */}
            </div>
            <Dash colorClass="bg-ming" />
          </div>

          <div className={`${loading ? "d-relative" : "d-none"} `}>
            <Loading />
          </div>
          {step === 1 && (
            <div className="card-body">
              <div className="row stepRow align-items-center">
                <div className="col-lg-1 col-2 position-relative">
                  <StaticImage
                    sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                    className={`circle `}
                    src={circle}
                    alt="alea"
                  />
                  <div className="position-absolute stepNumber lora">
                    {step}
                  </div>
                </div>
                <div className="col-lg-10 col-10">
                  <h2 className="stepTitle">{formTitle}</h2>
                </div>
              </div>
              <form>
                <div className={`step2`}>
                  <div className="row">
                    <div className="row noPadRight prompt-area">
                      <div className="row noPadRight col-lg-6">
                        {(!isObjectEmpty(errors) ||
                          (hasEnteredBirthday() && !validBirthday)) &&
                        isSubmitted ? (
                          <span className="offset-lg-3 offset-4 col-lg-9 col-8 alert-red prompt">
                            {labels.translation.errorInvalidInformation}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* firstname */}
                    <div className="row noPadRight col-lg-6 col-12 narrowMB">
                      <div className="row noPadRight">
                        <label
                          htmlFor="firstname"
                          className={`col-lg-3 col-4 col-form-label align-self-center ${
                            errors.firstname && "alert-red"
                          }`}
                        >
                          {labels.translation.firstNameLabel}*
                        </label>
                        <div className="col-lg-9 col-8 noPadRight">
                          <input
                            {...register("firstname", {
                              required: step === 1 ? true : false,
                            })}
                            type="text"
                            className={`form-control 
                      ${errors.firstname && "error"} 
                      ${
                        !errors.firstname &&
                        touchedFields.firstname &&
                        watchFirstName.length &&
                        "filled"
                      }
                      `}
                            placeholder="John"
                          />
                        </div>
                      </div>
                    </div>

                    {/* lastname */}
                    <div className="row noPadRight col-lg-6 col-sm-12 narrowMB">
                      {/* <div className="row prompt-area"></div> */}
                      <div className="row noPadRight">
                        <label
                          htmlFor="lastname"
                          className={`col-lg-3 col-4 col-form-label align-self-center  ${
                            errors.lastname && "alert-red"
                          }`}
                        >
                          {labels.translation.familyNameLabel}*
                        </label>
                        <div className=" col-lg-9 col-8 noPadRight">
                          <input
                            {...register("lastname", {
                              required: step === 1 ? true : false,
                            })}
                            type="text"
                            className={`form-control 
                      ${errors.lastname && "error"} 
                      ${
                        !errors.lastname &&
                        touchedFields.lastname &&
                        watchLastName.length &&
                        "filled"
                      }
                      `}
                            placeholder="Doe"
                          />
                        </div>
                      </div>
                    </div>

                    {/* phone with country code */}
                    <div className="row noPadRight col-lg-6 col-sm-12 narrowMB">
                      <div className="row noPadRight">
                        <label
                          htmlFor="phone"
                          className={`col-lg-3 col-4 col-form-label align-self-center ${
                            errors.phoneNumber && "alert-red"
                          }`}
                        >
                          {labels.translation.phoneLabel}*
                        </label>
                        <div className="col-lg-3 col-3 pe-0 countryCode">
                          <select
                            className={`form-select  ${
                              (errors.phoneNumber || !phoneValid) && "error"
                            } ${watchPhone.length && "filled"}`}
                            aria-label="Default select example"
                            defaultValue="+852"
                            {...register("countryCode", {
                              required: step === 1 ? true : false,
                            })}
                          >
                            {/* <option selected>+852</option> */}
                            {phoneCodeList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="col-lg-6 col-5 noPadRight ps-0 phone">
                          <input
                            type="number"
                            className={`form-control semiBold number ${
                              (errors.phoneNumber || !phoneValid) && "error"
                            } 
                    ${
                      !errors.phoneNumber &&
                      touchedFields.phoneNumber &&
                      watchPhone.length &&
                      "filled"
                    }`}
                            placeholder="6898 7415"
                            {...register("phoneNumber", {
                              required: step === 1 ? true : false,
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Email */}

                    <div className="row noPadRight col-lg-6 col-sm-12 narrowMB">
                      <div className="row noPadRight">
                        <label
                          htmlFor="email"
                          className={`col-lg-3 col-4 col-form-label align-self-center ${
                            errors.email && "alert-red"
                          }`}
                        >
                          {labels.translation.emailLabel}*
                        </label>
                        <div className="col-lg-9 col-8 noPadRight">
                          <input
                            type="email"
                            placeholder="name@example.com"
                            {...register("email", {
                              required: step === 1 ? true : false,
                              pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            className={`form-control 
                        ${errors.email && "error"} 
                        ${
                          !errors.email &&
                          touchedFields.email &&
                          watchEmail.length &&
                          "filled"
                        }
                      `}
                          />
                        </div>
                      </div>
                    </div>

                    {/* date fo birth */}
                    <div className="row noPadRight col-lg-6 col-md-12 wideMB birthdayRow">
                      <div className="row noPadRight">
                        <label
                          htmlFor="birthdate"
                          className={`col-lg-3 col-4 col-form-label semiBold align-self-center ${
                            (errors.birthday || !validBirthday) && "alert-red"
                          }`}
                        >
                          {labels.translation.dateBirthLabel}*
                        </label>

                        <div className="col-lg-9 col-8 ">
                          <input
                            type="text"
                            // className={`${style.datepicker}`}
                            id="birthdate"
                            className={`form-control 
                      ${(!validBirthday || errors.email) && "error"} 
                      ${
                        validBirthday &&
                        !errors.birthday &&
                        touchedFields.birthday &&
                        watchBirthday.length &&
                        "filled"
                      }`}
                            placeholder="DD/MM/YYYY"
                            // min={1}
                            // max={31}
                            // defaultValue={item.birthday}
                            {...register("birthday", {
                              required: step === 1 ? true : false,
                            })}
                            onChange={e => {
                              const newVal = birthdayMasking(e)
                              setValue("birthday", newVal)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row noPadRight col-lg-6 col-md-12 wideMB">
                      <div className="row noPadRight">
                        <label
                          htmlFor="residence"
                          className="col-4 col-lg-3 col-form-label align-self-center semiBold"
                        >
                          {labels.translation.countryResidenceLabel}
                        </label>
                        <div className="col-8 col-lg-9 noPadRight">
                          <select
                            style={
                              hasSelectedResidence()
                                ? openResidence
                                  ? {
                                      backgroundImage: `url(${arrowUp})`,
                                    }
                                  : {
                                      backgroundImage: `url(${arrowDown})`,
                                    }
                                : {
                                    backgroundImage: `url(${arrowGrey})`,
                                  }
                            }
                            className={`form-select residence grey-placeholder ${
                              !hasSelectedResidence()
                                ? "grey-placeholder"
                                : "filled"
                            } `}
                            aria-label={labels.translation.selectLabel}
                            onClick={() => {
                              if (!openResidence)
                                setOpenResidence(!openResidence)
                            }}
                            onKeyDown={() => {
                              if (!openResidence)
                                setOpenResidence(!openResidence)
                            }}
                            onMouseLeave={() => {
                              if (openResidence)
                                setOpenResidence(!openResidence)
                            }}
                            // defaultValue="Hong Kong"
                            {...register("residence", { required: false })}
                          >
                            <option value="">
                              {labels.translation.selectLabel}
                            </option>
                            {countryList.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" noPadRight d-flex flex-lg-row  justify-content-lg-end  buttonRow justify-content-between flex-row-reverse">
                      <div
                        role="button"
                        tabIndex={0}
                        className=" noPadRight  nextBtn justify-content-lg-end justify-content-center  order-lg-1 order-0"
                        onClick={handleSubmit(onSubmit)}
                        onKeyDown={handleSubmit(onSubmit)}
                      >
                        <Button
                          lang={lang}
                          text={data.submitLabel}
                          type="primary"
                          buttonId={"quote_page_step2_submit"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {step === 2 && <Confirmation lang={lang} context={formCompletion} />}
        </div>
      </div>

      {/* <div className="contactFormPage">
        <div className="container-fluid bg-daffodil text-center header position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{headerTitle}</h1>
        </div>
        <div className="container content card soft-corners soft-shadows">
          <h2 className="subtitle ming mb-4">{sectionTitle}</h2>
          <div className="ming">{sectionSubtitle}</div>
          <Dash />

          <div className={`contactForm`}>

      <div className={`${loading ? "d-relative" : "d-none"} `}>
        <Loading />
      </div>
      <div className="row stepRow align-items-center">
          <div className="col-lg-1 col-2 position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`circle `}
              src={circle}
              alt="alea"
            />
            <div className="position-absolute stepNumber lora">{step + 1}</div>
          </div>
          <div className="col-lg-10 col-10">
            <h2 className="stepTitle">{stepTitle[step]}</h2>
          </div>
        </div>
          </div>

        </div>
      </div> */}
    </Layout>
  )
}
export default ContactFormPage
